import React from 'react';
import styled, { css } from 'styled-components';


interface RoundButtonType {
	id: number,
	onClick: any,
	title: string,
	buttonType: string
}
export default function FullRoundButton({
	id,
	onClick,
	title,
	buttonType
}:RoundButtonType) {

	return (
		<> 
			<Button type='button' key={id} onClick={onClick} buttonType={buttonType}>{title}</Button>
		</>
	);	
}

interface btnCssType {
	buttonType: string
}
const Button = styled.button<btnCssType>`
	background-color: var(--btnBasicBG);
	color: var(--btnBasicText);
	border-radius: var(--btnSpecialRadius);	
	height: var(--btnBasicHeight);
	padding: 0 3rem 0.2rem 3rem;
	cursor: pointer;
	white-space: nowrap;
	${({buttonType}) =>
		buttonType==='White'
			? css`
			background-color: #fff;
			color: #333;
			border: 1px solid #ccc;
			`
			: css`
			background-color: var(--btnBasicBG);
			color: var(--btnBasicText);
			`
	}
`;