import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root {	
	
	/***************************************************************************************************************
	
		Element based global variable
	
	***************************************************************************************************************/

	/****************** common ***********************/
	--comColorFocus: #000;

	/******************* Font **********************/
	--fontBasicColor: #333;
	--fontBasicHoverColor: #000;
	--fontBasicSize: 1.6rem;
	--fontTitleSize: 2.5rem;
	--fontPlaceHolderColor: #ccc;

	/******************* Background **********************/
	--bgColorPageBasic: #ececec;
	--bgColorContentBasic: #fff;


	/******************* Radius **********************/
	--radiusBasic: 0.5rem;
	--radiusButtonBasic: 0.3rem;


	/******************* Line **********************/
	--lineWeightBasic: 0.05rem;
	--lineWeightFocus: 0.2rem;
	--lineColorBasic: #ccc;
	--lineColorFocus: #666;


	/******************* Padding Basic **********************/
	--paddingBasic: 1rem;


	/******************* Placeholder **********************/
	--placeholderSizeBasic: 1.4rem;
	--placeholderColorBasic: #ccc;


	/***************************************************************************************************************
	
		Component based global variable
	
	***************************************************************************************************************/

	/******************* button **********************/
	--btnBasicBG: #000;
	--btnDisabledBG: #ccc;
	--btnBasicText: #fff;
	--btnBasicWidth: 15rem;
	--btnBasicHeight: 3rem;
	--btnBasicRadius: var(--radiusButtonBasic);
	--btnBasicFontSize: 1.2rem;
	--btnBasicFontWeight: 500;
	--btnSpecialRadius: 1.5rem;
	--btnCancelBG: #ececec;
	--btnCancelText: #333;
	--btnBasicPadding: 1.5rem;


	/******************* Field alert messgae **********************/
	--alertFontSize: 1rem;
	--alertFontColor: #A90000;
	--alertFontWeight: 500;
	--alertMarginTop: 0.5rem;


	/******************* Content Page **********************/
	--pageTitleFontSize: 2.7rem;
	--pageTitleFontWeight: 500;


	// rem 단위를 사용하기 위함 
	font-size: 62.5%;
}

* {
	box-sizing: border-box;
}

*::placeholder {
	font-size: var(--placeholderSizeBasic);
	color: var(--placeholderColorBasic);
}

html body {
	margin: 0;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 1.6rem;
	box-sizing: border-box;
	// height: 100vh;
	// width: 100vw;
	// height: calc(var(--vh, 1vh) * 100);
	scroll-behavior: smooth;
	line-height: 1;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input {
	margin: 0;
	padding: 0;
	font-family: "Noto Sans KR", sans-serif;
	border: none;
	font-style: normal;
	box-sizing: border-box;
	word-break: keep-all;
	font-weight: 400;
}

legend {
	font-size: 1rem;
	color: #666;
	border: none;
	// font-weight: 400;
	padding-bottom: 0.3rem;
	// border: 1px solid dodgerblue;
}

input {
	outline: none;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-bottom: var(--lineWeightBasic) solid var(--lineColorBasic);
	font-family: "Noto Sans KR", sans-serif;
	height: 3.5rem;
	width: 100%;
	color: var(--fontBasicColor);
	font-size: 1.6rem;
	padding-left: var(--paddingBasic);
	/* iOS에서 모서리가 둥글게 나오는 현상 해결 
	-webkit-appearance: none;
	-webkit-border-radius: var(--radiusBasic);
	*/
}

input:focus {
	border-bottom: var(--lineWeightFocus) solid var(--lineColorFocus);
	/* border 두께가 변경되면서 글자가 움직이는 현상을 잡기 위함 */
	/* margin: -0.3px; */
}

a, 
a:hover,
a:active,
a:visited {
	text-decoration: none;
	color: var(--fontBasicHoverColor);
}

/* Select box css */
select {	
	outline: none;
	border: 0;
	width: 100%;
	height: 36px;
	border-bottom: 0.5px solid #ccc;	
	padding: 10px 10px 6px 10px;
	font-size: 16px;
	-webkit-appearance: none;  /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    background: url('assets/icons/dropdown_expandmore.png') right 10px center no-repeat;
	background-color: #fff;
}

select::-ms-expand {
	display: none;
}
select:focus {
	// border-bottom: var(--comLineWeightFocus) solid var(--comColorFocus);	
	outline: none;
	background: url('assets/icons/dropdown_expandless.png') right 10px center no-repeat;
	background-color: #fff;
	transition: .2s;
	/* padding-bottom: 1px; */
}

/* textarea */
textarea {
	border: var(--lineWeightBasic) solid var(--lineColorBasic);
	font-family: "Noto Sans KR", sans-serif;
	font-size: var(--fontBasicSize);
	resize: none;
	border-radius: var(--radiusButtonBasic);
	width: 100%;
	padding: 10px
}

textarea:focus {
	border: var(--lineWeightFocus) solid var(--lineColorFocus);
	outline: none;
	color: var(--fontBasicColor);
}

textarea::placeholder {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 16px;
}
/* h tag */
h1,h2,h3,h4,h5,h6 {
	margin-top: 0;
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

// 한 줄의 Filed를 구성하기 위한 공통 class
.FieldDiv {
	height: 80px;
	// padding-top: 8px;
	vertical-align: bottom;
	// border: 1px solid red;
}

// 큰 버튼 여러개를 한줄로 표현하기 위함
.ButtonWrap {
	width: 100%;
	// border: 1px solid red;
	display: flex;
	justify-content: center;
	padding: 3rem 0 0 0;
}

// Table Grid div
.tableGrid	{
	width: 100%;
	display: flex;
	flex-grow: 1;
}

//quill editor
.ql-editor strong{
  font-weight:bold;
}
`