import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import { GeneralButton, Input, InputAlert, PageTitle, Popup } from '@components';
import { useNavigate } from 'react-router-dom';
// import { Textarea } from '@components';
import { blockEnterKey, inputCheck } from '@handler';
import { alertControlType, postNoticeProcUrl } from '@env';
import { formSubmit } from '@service';
import { QuillEditorAdmin } from '@components';

function NoticeWrite() {
	
	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	const [btnDisabled, setBtnDisabled] = useState<boolean>(true)

	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);


	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	const [subject, setSubject] = useState<string>('');
	const [sbjAlertCtl, setSbjAlertCtl] = useState<alertControlType>({dp: true, msg: ''})
	const [desc, setDesc] = useState<string>('');
	const [descAlertCtl, setDescAlertCtl] = useState<alertControlType>({dp: true, msg: ''	})

	const subjectHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		// e.stopPropagation();

		let result = inputCheck(e.target.value, 5, '제목');
		if(result.isChecked) {
			setSubject(result.value)
			setSbjAlertCtl({dp: false, msg: ''})
		} else {
			setSbjAlertCtl({dp: true, msg: result.alertMessage})
		}
		
	},[])

	const contentHandler = useCallback((html: string): void => {
		// e.preventDefault();
		let result = inputCheck(html, 10, '내용')
		setDesc(html);	// Editor에서 입력값이 사라지는 현상 대비

		if(result.isChecked) {
			setDescAlertCtl({dp: false, msg: ''})
			setDesc(result.value);
		} else {
			setDescAlertCtl({dp: true, msg: result.alertMessage})
		}
	},[])


	const goList = useCallback((e:React.ChangeEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate('/notice', {replace: true});
	},[navigate])


	/****************************************************** 데이터 등록/수정/삭제 ***************************************************/
	const noticeSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// e.stopPropagation();

		let form = document.getElementsByName('noticeForm')[0];
		
		try {
			await formSubmit(postNoticeProcUrl, form, 'GENERAL')
				.then((res) => {
					let data = res.data;
					if(data.success && data.response.resultCode === 0) {
						setPopCode('REGIST_COMPLETE')
						setPopDp(true)
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode)
			setPopDp(true);
		}

	},[])

	useEffect(() => {
		(sbjAlertCtl.dp===false && descAlertCtl.dp===false) ? setBtnDisabled(false) : setBtnDisabled(true);
		// 엔터키 방지
		blockEnterKey();
	},[sbjAlertCtl.dp, descAlertCtl.dp])

	return (
		<> 
			<PageTitle title='공지사항' />
			<TableWrap>
				<form name='noticeForm'>
					<fieldset>
						<input type='hidden' name='procMode' value='C' />
						<Input
							type='text' 
							name='ntTitle' 
							title='제목' 
							placeholder='제목을 입력해주세요.'
							onchange={subjectHandler}
							alertMessage=''
							maxLength={30}
							readonly={false}
							value={subject}
						/>
						{sbjAlertCtl.dp && <InputAlert alertMsg={sbjAlertCtl.msg} width='100%' />}
						<div style={{height: '50px'}}></div>
						<QuillEditorAdmin value={desc} onChange={contentHandler} style={{height: '500px'}}/>
						{/* <Textarea 
							name='ntContents'
							placeholder='내용을 입력해주세요'
							maxLength={1000}
							onchange={contentHandler}
							title='내용'
							style={textAreaHeight}
							value={desc}
						/> */}
						{/*  편집기의 위 header의 높이로 인해 일정 부분 아래와 겹치는 현상 발생하는 문제로 필요 */}
						<div style={{height: '50px'}}></div>
						<textarea name='ntContents' defaultValue={desc} style={{display:'none'}} />
						{descAlertCtl && <InputAlert alertMsg={descAlertCtl.msg} width='100%' />}
						<div className='ButtonWrap'>
							<GeneralButton title='목록' disabled={false} buttonType='Big_Cancel' buttonLink={goList} />&nbsp;&nbsp;&nbsp;&nbsp;
							<GeneralButton title='등록' disabled={btnDisabled} buttonType='Big_Normal' buttonLink={noticeSubmit} />
						</div>
					</fieldset>
				</form>
			</TableWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const TableWrap = styled.div`
	width: 100%;	
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	padding: 3rem 2rem;
`

export default React.memo(NoticeWrite)