// 로그인 처리
export const postManageLoginUrl: string = '/no-auth/manager-login';
// 토큰 리프레시
export const getTokenRefreshUrl: string = '/no-auth/manager-reissue';
// 첨부파일 다운로드
export const postFileDownloadUrl: string = '/user/file-download';
// 공지사항 목록 조회
export const postNoticeListUrl: string = '/manage/notices';
// 공지사항 등록/수정/삭제
export const postNoticeProcUrl: string = '/manage/notice-proc';
// 공지사항 상세 조회
export const getNoticeDetailUrl: string = '/manage/notice?ntSeq=';
// 회원 목록 조회
export const postMemberListUrl: string = '/manage/members';
// 회원 상세 조회
export const getMemberDetailUrl: string = '/manage/member?lgSeq=';
// 프로젝트 목록 조회
export const postProjectListUrl: string = '/manage/projects';
// 문서 목록 조회
export const postDocListUrl: string = '/manage/documents';
// 문서 상세 조회
export const getDocDetailUrl: string = '/manage/document?dcSeq=';
// 요청사항 목록 조회
export const postRequestListUrl: string = '/manage/requests';
// 요청사항 상세 조회
export const getRequestDetailUrl: string = '/manage/request?rqSeq='