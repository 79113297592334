import { GeneralButton, Input, PageTitle, Popup } from '@components';
import { getMemberDetailUrl } from '@env';
import { getData } from '@service';
import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

function MemberDetail() {
	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	// 넘어온 값
	const { id } = useParams();
	
	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);
	

	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [lastLoginDate, setLastLoginDate] = useState<string>('');
	const [loginFailedCnt, setLoginFailedCnt] = useState<number>(0);
	const [registDate, setRegistDate] = useState<string>('');
	const [dirUsageMB, setDirUsageMB] = useState<number>(0);

	const goList = useCallback((e:React.ChangeEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate('/member', {replace: true});
	},[navigate])


	const pageDataHandling = () => {
	}


	/****************************************************** 데이터 가져오기/수정/삭제 ***************************************************/
	const getMember = useCallback(async () => {

		try {
			await getData(getMemberDetailUrl + id)
				.then((res) => {
					// console.log(res);
					let data = res.data;
					if(data.success) {
						setName(data.response.lgName)
						setEmail(data.response.lgId)
						setLastLoginDate(data.response.lgLastLoginDate)
						setLoginFailedCnt(data.response.lgFailCnt)
						setRegistDate(data.response.lgJoinDate)
						setDirUsageMB(data.response.lgDirSizeMb)
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode);
			setPopDp(true);
		}
	},[id]);

	useEffect(() => {
		getMember();
	},[getMember])

	return (
		<>
			<PageTitle title='회원관리' />
			<TableWrap>
				<FlexDivBox width='50%' padding='0 1rem 3rem 0'>
					<Input
						type='text' 
						name='email' 
						title='이메일' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={email}
					/>
				</FlexDivBox>
				<FlexDivBox width='50%' padding='0 0 3rem 1rem'>
					<Input
						type='text' 
						name='name' 
						title='이름' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={name}
					/>
				</FlexDivBox>
				<FlexDivBox width='50%' padding='0 1rem 3rem 0'>
					<Input
						type='text' 
						name='lastLoginDate' 
						title='마지막 로그인 일시' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={lastLoginDate}
					/>
				</FlexDivBox>
				<FlexDivBox width='50%' padding='0 0 3rem 1rem'>
					<Input
						type='text' 
						name='loginFailedCnt' 
						title='로그인 실패 횟수' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={loginFailedCnt}
					/>
				</FlexDivBox>
				<FlexDivBox width='50%' padding='0 1rem 3rem 0'>
					<Input
						type='text' 
						name='registDate' 
						title='가입일시' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={registDate}
					/>
				</FlexDivBox>
				<FlexDivBox width='50%' padding='0 0 3rem 1rem'>
					<Input
						type='text' 
						name='dirUsageMB' 
						title='디렉토리 사용량(MB)' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={dirUsageMB}
					/>
				</FlexDivBox>
				<div style={{height: '50px'}}></div>
				<div className='ButtonWrap'>
					<GeneralButton title='목록' disabled={false} buttonType='Big_Cancel' buttonLink={goList} />
				</div>
			</TableWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const TableWrap = styled.div`
	width: 100%;	
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	padding: 3rem 2rem;
	display: flex;
	flex-wrap: wrap;
`
interface FlexDivType {
	width: string,
	padding?: string|null
}
const FlexDivBox = styled.div<FlexDivType>`
	width: ${(props) => props.width};
	padding: ${(props) => props.padding};
`;

export default React.memo(MemberDetail)