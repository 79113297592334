import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
	buttonLink: any,
	title: string,
	disabled: boolean,
	buttonType?: string | null
}

export default function GeneralButton({
	buttonLink,
	title,
	disabled,
	buttonType = null
}: ButtonProps) {
	// console.log(typeof buttonLink);	
	return (
		<>
			<ButtonArea>
				<Button type='button' onClick={buttonLink} disabled={disabled} buttonType={buttonType}>{title}</Button>
			</ButtonArea>
		</>
	);	
}

const ButtonArea = styled.div`
	text-align: center;
	margin-bottom: 10px;
`;

interface ButtonCssProps {
	buttonType?: string | null
}
const Button = styled.button<ButtonCssProps>`	
	height: var(--btnBasicHeight);
	border-radius: var(--btnBasicRadius);
	cursor: pointer;
	font-size: var(--btnBasicFontSize);
	font-weight: var(--btnBasicFontWeight);
	padding-bottom: 0.1rem;
	${( { buttonType }) => {
		switch (buttonType) {
			case 'Big_Normal':
				return css`width: var(--btnBasicWidth); background-color: var(--btnBasicBG); color: var(--btnBasicText);`
			case 'Big_Cancel':
				return css`width: var(--btnBasicWidth); background-color: var(--btnCancelBG); color: var(--btnCancelText)`
			case 'Small_Normal':
				return css`padding-left: var(--btnBasicPadding); padding-right: var(--btnBasicPadding); background-color: var(--btnBasicBG); color: var(--btnBasicText);`
			case 'Small_Cancel':
				return css`padding-left: var(--btnBasicPadding); padding-right: var(--btnBasicPadding); background-color: var(--btnCancelBG); color: var(--btnCancelText);`
			default:
				return css`padding-left: var(--btnBasicPadding); padding-right: var(--btnBasicPadding); background-color: var(--btnBasicBG); color: var(--btnBasicText);`
		}
	}};
	&:disabled {
		background-color: var(--btnDisabledBG);
	}
`;



// ${( { buttonType }) =>
// 		buttonType==='General'
// 			? css`width: var(--btnBasicWidth);` 
// 			: css`padding-left: var(--btnBasicPadding);	padding-right: var(--btnBasicPadding);`
// 	};