import React from 'react';


interface TextareaType {
	name: string,
	placeholder: string,
	maxLength: number,
	onchange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
	title: string,
	value?: any,
	style?: object,
}

export default function Textarea({
	name,
	placeholder,
	maxLength,
	onchange,
	title,
	value = null,
	style = {}
}: TextareaType) {
	return (
		<div>
			<legend>{title}</legend>
			<textarea name={name} placeholder={placeholder} maxLength={maxLength} onChange={onchange} defaultValue={value} style={style}></textarea>
		</div>
	)
}