import { xLargeDark } from '@assets';
import { msgDefine } from '@env';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components'
import GeneralButton from '../button/GeneralButton';


interface PopupProps {
	popcode: string,
	okFunc: (e: React.ChangeEvent<HTMLButtonElement>) => void,
	cancelFunc?: any,
	bgFunc: (e: React.MouseEvent) => void,
	popupType?: any
}

function Popup({
	popcode,
	okFunc,
	cancelFunc = null,
	bgFunc,
	popupType = null
}: PopupProps) {

	const [popTitle, setPopTitle] = useState<string>('');
	const [popContent, setPopContent] = useState<string>('');

	useEffect(() => {
		let result = msgDefine(popcode);
		setPopTitle(result.title);
		setPopContent(result.message);
	},[popcode])

	return (
		<>
			<PopWrap>
				<TitleArea>
					<PopTitle>{popTitle}</PopTitle>
					<PopClose onClick={bgFunc} >
						<img src={xLargeDark} alt='close '/>
					</PopClose>
				</TitleArea>
				<PopContent>
					{popContent}
				</PopContent>
				{popupType==='confirm' && <GeneralButton title='취소' disabled={false} buttonLink={cancelFunc} />}
				<GeneralButton title='확인' disabled={false} buttonLink={okFunc} />
			</PopWrap>
			<ComBG onClick={bgFunc} />
		</>
	)
}

const PopWrap = styled.div`
	min-width: 25rem;
	border: 0.5px solid #ececec;
	border-radius: var(--radiusBasic);
	background-color: #fff;
	z-index: 100;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 2rem;
`;

const TitleArea = styled.div`
	position: relative;	
	width: 100%;
	display: flex;	
`;

const PopTitle = styled.div`
	font-size: 2.5rem;
	color: var(--fontBasicColor);
	line-height: 1;	
	font-weight: 500;
`;

const PopClose = styled.p`
	display: inline-block;	
	position: absolute;
	right: 0;
	cursor: pointer;
`;

const PopContent = styled.div`
	font-size: 1.4rem;
	color: var(--fontBasicColor);
	padding: 3rem 0 3rem 0;
	// border: 1px solid red;
	white-space: pre-wrap;
	line-height: 20px;
`;

const ComBG = styled.div`
	background-color: #000;
	opacity: 65%;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
`;

export default Popup