import { GeneralButton, Input, PageTitle, Popup, Textarea } from '@components';
import { FileInfoType, getDocDetailUrl } from '@env';
import { getData } from '@service';
import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { FullRoundButton } from '@components';
import styled from 'styled-components';
import { downloadFiles } from '@handler';

function DocumentDetail() {
  /****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	// 넘어온 값
	const { id } = useParams();
	
	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);
	

	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	const [pjSeq, setPjSeq] = useState<string>('');
	const [pjName, setPjName] = useState<string>('');
	const [docKindTitle, setDocKindTitle] = useState<string>('');
	const [docTitle, setDocTitle] = useState<string>('');
	const [docContent, setDocContent] = useState<string>('');
	const [docAttendees, setDocAttendees] = useState<string>('');
	const [docOpenYn, setDocOpenYn] = useState<string>('');
	const [docRegistDate, setDocRegistDate] = useState<string>('');
	const [docRegistId, setDocRegistId] = useState<string>('');
	const [docRegistName, setDocRegistName] = useState<string>('');
	const [attachedFile, setAttachedFile] = useState<[]>([]);

	// text area style
	const textAreaHeight = { height: '40rem' }

	const goList = useCallback((e:React.ChangeEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate('/document', {replace: true});
	},[navigate])


	const pageDataHandling = () => {
	}

	const fileDownload = (e: React.ChangeEvent<HTMLButtonElement>, seq: number, fileName: string) => {
		e.preventDefault();
		downloadFiles(seq, fileName, 'D')
	}


	/****************************************************** 데이터 가져오기/수정/삭제 ***************************************************/
	const getMember = useCallback(async () => {

		try {
			await getData(getDocDetailUrl + id)
				.then((res) => {
					// console.log(res);
					let data = res.data;
					if(data.success) {
						setPjSeq(data.response.pjSeq)
						setPjName(data.response.pjName)
						setDocKindTitle(data.response.dcKindTitle)
						setDocTitle(data.response.dcTitle)
						setDocContent(data.response.dcContents)
						setDocAttendees(data.response.dcAttendees)
						setDocOpenYn(data.response.dcOpenYn)
						setDocRegistDate(data.response.dcRegDate)
						setDocRegistId(data.response.dcRegId)
						setDocRegistName(data.response.dcRegName)
						setAttachedFile(data.response.documentFiles)
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode);
			setPopDp(true);
		}
	},[id]);

	useEffect(() => {
		getMember();
	},[getMember])

	
	return (
		<>
			<PageTitle title='문서관리' />
			<TableWrap>
				<FlexDivBox width='10%' padding='0 1rem 3rem 0'>
					<Input
						type='text' 
						name='pjSeq' 
						title='프로젝트번호' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={pjSeq}
					/>
				</FlexDivBox>
				<FlexDivBox width='60%' padding='0 1rem 3rem 1rem'>
					<Input
						type='text' 
						name='pjName' 
						title='프로젝트 이름' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={pjName}
					/>
				</FlexDivBox>
				<FlexDivBox width='20%' padding='0 1rem 3rem 1rem'>
					<Input
						type='text' 
						name='docKindTitle' 
						title='문서 종류명' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={docKindTitle}
					/>
				</FlexDivBox>
				<FlexDivBox width='10%' padding='0 0 3rem 1rem'>
					<Input
						type='text' 
						name='docOpenYn' 
						title='공개여부' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={docOpenYn}
					/>
				</FlexDivBox>
				<FlexDivBox width='33%' padding='0 1rem 3rem 0'>
					<Input
						type='text' 
						name='docRegistId' 
						title='등록자 아이디' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={docRegistId}
					/>
				</FlexDivBox>
				<FlexDivBox width='33%' padding='0 1rem 3rem 1rem'>
					<Input
						type='text' 
						name='docRegistName' 
						title='등록자 이름' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={docRegistName}
					/>
				</FlexDivBox>
				<FlexDivBox width='33%' padding='0 0 3rem 1rem'>
					<Input
						type='text' 
						name='docRegistDate' 
						title='등록일시' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={30}
						readonly={true}
						value={docRegistDate}
					/>
				</FlexDivBox>
				<FlexDivBox width='100%' padding='0 0 3rem 0'>
					<Input
						type='text' 
						name='docAttendees' 
						title='참석자' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={100}
						readonly={true}
						value={docAttendees}
					/>
				</FlexDivBox>
				<FlexDivBox width='100%' padding='0 0 3rem 0'>
					<Input
						type='text' 
						name='docTitle' 
						title='문서 제목' 
						placeholder=''
						onchange={pageDataHandling}
						alertMessage=''
						maxLength={100}
						readonly={true}
						value={docTitle}
					/>
				</FlexDivBox>
				<FlexDivBox width='100%' padding='0 0 3rem 0'>
					<Textarea 
						name='ntContents'
						placeholder='내용을 입력해주세요'
						maxLength={1000}
						onchange={pageDataHandling}
						title='내용'
						style={textAreaHeight}
						value={docContent}
						/>
				</FlexDivBox>
				<FlexDivBox width='100%' padding='0 0 3rem 0'>
					{attachedFile.map((data: FileInfoType) => {
						// console.log(data);
						return (
							<FullRoundButton id={data.dfSeq} title={data.dfFilename} onClick={(e: React.ChangeEvent<HTMLButtonElement>) => fileDownload(e, data.dfSeq, data.dfFilename)} buttonType='' />
						)
					})}
				</FlexDivBox>

				<div style={{height: '50px'}}></div>
				<div className='ButtonWrap'>
					<GeneralButton title='목록' disabled={false} buttonType='Big_Cancel' buttonLink={goList} />
				</div>
			</TableWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const TableWrap = styled.div`
	width: 100%;	
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	padding: 3rem 2rem;
	display: flex;
	flex-wrap: wrap;
`
interface FlexDivType {
	width: string,
	padding?: string|null
}
const FlexDivBox = styled.div<FlexDivType>`
	width: ${(props) => props.width};
	padding: ${(props) => props.padding};
`;

export default React.memo (DocumentDetail)