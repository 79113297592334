import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DocumentDetail, DocumentList, Login, MemberDetail, MemberList, NoticeDetail, NoticeList, NoticeWrite, ProjectList, RequestDetail, RequestList } from "@pages";
import { Layout } from '@components';
import PrivateRoute from './PrivateRoute'
import { GlobalStyle } from '../global-style';

// Error 페이지 정의
// import Error404 from "pages/common/error/Error404";

// Layout 페이지 정의

export default function Router() {
	return (
		<BrowserRouter>
			<GlobalStyle />
			<Routes>
				{/* <GlobalStyle /> */}
				{/* 인증 여부 상관 없이 접속 가능한 페이지 정의 */}
				{/* <Route index element={<DefaultLayout><MainPage/></DefaultLayout>}/> */}
				{/* <Route index element={<MainPage />} />
				<Route path="/layout" element={<LayoutPage />} />
				<Route path="/files" element={<FilePage />} />
				<Route path="/editor" element={<WEditor />} /> */}

				<Route index element={<Login />} />

				{/* 인증을 반드시 하지 않아야만 접속 가능한 페이지 정의 */}
				<Route element={<PrivateRoute authentication={false} />}>
					<Route path="/Login"
						element={
							<Layout><Login /></Layout>
						}
					/>
				</Route>

				{/* 인증을 반드시 해야지만 접속 가능한 페이지 정의 */}
				<Route element={<PrivateRoute authentication={true} />}>
					<Route element={<Layout />}>
						<Route path="/member" element={<MemberList />} />
						<Route path="/member/:id" element={<MemberDetail />} />
						<Route path="/project" element={<ProjectList />} />
						<Route path="/notice" element={<NoticeList />} />
						<Route path="/notice/write" element={<NoticeWrite />} />
						<Route path="/notice/:id" element={<NoticeDetail />} />
						<Route path="/request" element={<RequestList />} />
						<Route path="/request/:id" element={<RequestDetail />} />
						<Route path="/document" element={<DocumentList />} />
						<Route path="/document/:id" element={<DocumentDetail />} />
					</Route>
					{/* 권한 체크가 필요한 페이지 정의 */}
					{/* ProtectRoute는 반드시 로그인한 사용자의 한해서만 되도록 구현되어 PrivateRoute안에 종속되어야한다. */}
					{/* <Route element={<ProtectRoute />}>
						<Route element={<AdminDefaultLayout />}>
						<Route path="/admin" element={<AdminMainPage />} />
						<Route path="/admin/member" element={<MemberManageMainPage />} />
						</Route>
					</Route> */}
				</Route>

				{/* 인증/권한 여부와 상관 없이 접근 가능한 Error 페이지 정의 */}
				{/* <Route path="/*" element={<Error404 />} /> */}
			</Routes>
		</BrowserRouter>
	);
}
