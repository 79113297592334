import React from 'react';
import styled from 'styled-components';
import GeneralButton from '../button/GeneralButton';

// interface FuncProps {
// 	disabled: boolean,
// 	title: string,
// 	buttonLink: () => void,
// 	buttonType: string
// }

interface PageProps {
	title: string,
	buttonData?: any
}

export default function PageTitle({
	title,
	buttonData
}: PageProps): React.ReactElement {

	// console.log(buttonData);

	return (
		<ContentHeader>
			<PageContentTitle>{title}</PageContentTitle>
			<ButtonArea>
				{buttonData && <GeneralButton disabled={buttonData.disabled} buttonLink={buttonData.buttonLink} title={buttonData.title} buttonType={buttonData.buttonType} />}
			</ButtonArea>
		</ContentHeader>
	)
}

const ContentHeader = styled.div`
	width: 100%;
	display: flex;
	// border: 1px solid red;
	line-height: 1;
	height: 40px;
	margin-bottom: 40px;
`;

const ButtonArea = styled.div`
	display: flex;
	// border: 1px solid yellowgreen;
`;

const PageContentTitle = styled.div`
	font-size: var(--pageTitleFontSize);	
	font-weight: var(--pageTitleFontWeight);
	flex-grow: 1;
`;