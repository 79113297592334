interface popMsg {
	title: string,
	message: string
}

const msgDefine = (code: string): popMsg => {
	// console.log(errCode);
	switch(code) {
		case 'AUTH_DENIED': 
			return {title: '주의', message: '권한이 없습니다.'}
		case 'ERROR_AUTH':
			return {title: '주의', message: '이메일 또는 비밀번호가 일치하지 않습니다.'}
		case 'INVALID_INPUT_PARAM':
			return {title: '알림', message: '입력값을 확인해주세요.'}
		case 'DATA_NOT_FOUND':
			return {title: '알림', message: '조회된 데이터가 없습니다.'}
		case 'DATA_ALREADY_EXISTS':
			return {title: '알림', message: '존재하는 데이터 입니다.'}
		case 'WORK_FAIL':
			return {title: '주의', message: '요청 작업에 실패했습니다.'}
		case 'SERVER_ERROR':
			return {title: '주의', message: '서버 에러가 발생했습니다.'}
		case 'NOT_FOUND':
			return {title: '주의', message: '요청 주소가 잘못됐습니다.'}
		case 'TOKEN_EXPIRE':
			return {title: '주의', message: '인증이 만료됐습니다.'}
		case 'TOKEN_FAIL':
			return {title: '주의', message: '인증에 실패했습니다.'}
		case 'NOT_TOKEN':
			return {title: '알림', message: '인증값이 없습니다.'}
		case 'LOGIN_FAILED':
			return {title: '알림', message: '이메일 또는 비밀번호를 확인해주세요.'}
		case 'AXIOS_ERROR':	// Axios 에러
			return {title: '알림', message: '네트워크 에러가 발생했습니다.\n관리자에게 문의해주세요.'}
		case 'EMAIL_EXIST':
			return {title: '알림', message: '이미 가입되어 있는 이메일 주소입니다.'}
		case 'INVITE_EMAIL':
			return {title: '알림', message: '초대된 이메일 주소입니다.\n초대 이메일 링크를 통해 가입해주세요.'}
		case 'MEMBER_REGIST_FAILED':
			return {title: '알림', message: '회원가입이 되지 않았습니다.\n관리자에게 문의해주세요.'}
		case 'CONFIRM_DELETE':
			return {title: '확인', message: '삭제하시겠습니까?'}
		case 'CONFIRM_MODIFY':
			return {title: '확인', message: '수정하시겠습니까?'}
		case 'REGIST_COMPLETE':
			return {title: '알림', message: '등록되었습니다.'}
		case 'MODIFY_COMPLETE':
			return {title: '알림', message: '수정되었습니다.'}
		case 'DELETE_COMPLETE':
			return {title: '알림', message: '삭제되었습니다.'}
		case 'DELETE_FILE_COMPLETE':
			return {title: '알림', message: '파일을 삭제했습니다.'}
		case 'DELETE_FILE_FAILED':
			return {title: '알림', message: '파일 삭제에 실패했습니다.'}
		case 'MEMBER_EXIST':
			return {title: '주의', message: '이미 등록되어 있습니다.'}
		case 'MEMBER_OWNER':
			return {title: '알림', message: '프로젝트 관리자는 멤버 또는 고객으로 등록할 수 없습니다..'}
		case 'MEMBER_INVITED':
			return {title: '알림', message: '이미 초대되었습니다.'}
		case '-1':
			return {title: '알림', message: '작업에 실패했습니다.\n관리자에게 문의해주세요.'}
		case 'INVITE_COMPLETE':
			return {title: '알림', message: '등록 및 초대를 완료했습니다.'}
		case 'FILE_MAXSIZE':
			return {title: '주의', message: '파일 사이즈는 3MB까지만 등록 가능합니다.'}
		case 'FILE_MSXCNT':
			return {title: '주의', message: '파일은 3개까지 등록 가능합니다.'}
		case 'FILE_TYPE':
			return {title: '주의', message: '등록할 수 없는 파일종류 입니다.'}
		case 'QNA_REGIST_COMPLETE':
			return {title: '알림', message: '문의사항이 등록됐습니다.\n최대한 빠른시간안에 답변 드리겠습니다.'}
		case 'PWDEMAIL_SEND_COMPLETE':
			return {title: '알림', message: '임시 비밀번호를 입력하신 메일주소로 발송했습니다.\n이메일을 확인해주세요.'}
		case 'MEMBER_NOEXIST':
			return {title: '알림', message: '가입 정보가 없습니다.\n회원 가입 후 이용해주세요.'}
		case 'PROJECT_DELETE_FAILED':
			return {title: '주의', message: '프로젝트 삭제에 실패했습니다.\n멤버, 고객 또는 등록된 데이터가 있는경우 삭제가 불가합니다.\n이외의 경우에는 관리자에게 문의해주세요.'}
		default:
			return {title: '주의', message: '알수없는 에러가 발생했습니다.\n아래의 코드와 함께 관리자에게 문의해주세요.\nERR_CODE:' + code}
	}
}

export default msgDefine;