// get Parameter를 가져온다.
export function getParams (getName: string) {
	let params = new URLSearchParams(window.location.search);
	return params.get(getName);
}

// 엔터키 방지
export function blockEnterKey (): void {
	document.addEventListener('keydown', function(event) {
		if(event.keyCode === 13) {
			event.preventDefault();
		}
	})
}