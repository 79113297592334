import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router'
import Gnb from './Gnb'

interface LayoutDefaultProps {
	children?: React.ReactElement;
}

function Layout({children}: LayoutDefaultProps) {
	return (
		<GirocAdminWrapper>
			<Gnb />
			<PageArea>
				{children || <Outlet />}
			</PageArea>
		</GirocAdminWrapper>
	)
}

const GirocAdminWrapper = styled.div`
	display: flex;
	width: 100%;
	// width: 100vw;
	// height: 100vh;
	background-color: var(--bgColorPageBasic);
`

const PageArea = styled.div`
	width: 100%;
	padding: 3rem;
`
export default Layout