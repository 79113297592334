import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { ExitToAppRoundedIcon } from '@assets';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { goSignOut } from '@service';

function Gnb() {

	// 얼마나 로딩되는지 파악하기 위함
	console.log('GNB Loading');

	/****************************************************** 공통 정의 ***************************************************/
	const navigate = useNavigate();

	const menuHandler = (e: React.MouseEvent<HTMLDivElement>, type: string) => {
		e.preventDefault();
		switch (type) {
			case 'member': 
				navigate('/member', {replace: true});
				break;
			case 'project': 
				navigate('/project', {replace: true});
				break;
			case 'request':	
				navigate('/request', {replace: true});
				break;
			case 'document': 
				navigate('/document', {replace: true});
				break;
			case 'notice': 
				navigate('/notice', {replace: true});
				break;
			default: 
				navigate('/member', {replace: true});
				break;
		}
	}


	const signOut = () => {
		if(goSignOut()) {
			// 하위 컴포넌트에서 signOut 시킬 경우 이 컴포넌트에서만 navigate가 작동하므로 window 명령어를 쓴다.
			window.location.href='/';
			// navigate('/', {replace: true});
		}
	}

	return (
		<GnbWrap>
			<MyInfoArea>
				<span>박재현님 안녕하세요!</span>&nbsp;&nbsp;<span><ExitToAppRoundedIcon fontSize='large' color='info' style={{cursor: 'pointer'}} onClick={signOut} /></span>
			</MyInfoArea>
			<MenuArea onClick={(e) => menuHandler(e, 'member')}>
				<ArrowForwardIosIcon fontSize='small' /> 회원관리
			</MenuArea>
			<MenuArea onClick={(e) => menuHandler(e, 'project')}>
				<ArrowForwardIosIcon fontSize='small' /> 프로젝트 관리
			</MenuArea>
			<MenuArea onClick={(e) => menuHandler(e, 'request')}>
				<ArrowForwardIosIcon fontSize='small' /> 요청사항 관리
			</MenuArea>
			<MenuArea onClick={(e) => menuHandler(e, 'document')}>
				<ArrowForwardIosIcon fontSize='small' /> 문서/회의록 관리
			</MenuArea>
			<MenuArea onClick={(e) => menuHandler(e, 'notice')}>
				<ArrowForwardIosIcon fontSize='small' /> 공지사항 관리
			</MenuArea>
		</GnbWrap>
	)
}

const GnbWrap = styled.div`
	width: 25rem;
	height: calc(var(--vh, 1vh) * 100);
	font-size: 1.4rem;
	border-right: 1px solid #ccc;
	background-color: #666;
`

const MyInfoArea = styled.div`
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	// padding-top: 10px;
	background-color: #fff;
`

const MenuArea = styled.div`
	height: 50px;
	background-color: #333;
	font-size: 1.4rem;
	font-weight: 400;
	color: #fff;
	border-bottom: 1px solid #666;
	padding: 18px 5px 10px 10px;
	cursor: pointer;
`

export default React.memo(Gnb);