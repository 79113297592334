import { PageTitle, Popup } from '@components';
import { postMemberListUrl } from '@env';
import { DataGrid } from '@mui/x-data-grid';
import { formSubmit } from '@service';
import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function MemberList() {

	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);

	/****************************************************** Data Grid 관련 정의 ***************************************************/
	const [pageSize, setPageSize] = useState<number>(20);		// 한 페이지 사이트
	const memberColumn: any = [
		{ 
			"field": "id", 
			"headerName": "회원번호", 
			"width": 80,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "lgId", 
			"headerName": "이메일", 
			"flex": 1,
			"headerAlign": "center",
			"align": "left"
		},
		{ 
			"field": "lgName", 
			"headerName": "이름", 
			"width": 150,
			"headerAlign": "center",
			"align": "center"
		}
		,
		{ 
			"field": "lgProjectCount", 
			"headerName": "프로젝트 생성 개수", 
			"width": 150,
			"headerAlign": "center",
			"align": "center"
		}
		,
		{ 
			"field": "lgDirSizeGb", 
			"headerName": "디렉토리 사용용량(GB)", 
			"width": 150,
			"headerAlign": "center",
			"align": "center"
		}
		,
		{ 
			"field": "lgJoinDate", 
			"headerName": "가입일시", 
			"width": 200,
			"headerAlign": "center",
			"align": "center"
		}
	];
	
	const viewContent = useCallback ((e: any)=> {
		navigate('/member/' + e.id, {replace:true})
	},[navigate]);

	/****************************************************** Data 가져오기 ***************************************************/
	const [datas, setDatas] = useState<noticListType[]>([]);
	interface noticListType {
		id: string,
		lgId: string,
		lgName: string,
		lgProjectCount: string,
		lgDirSizeGb: string,
		lgJoinDate: string,
	}

	const getMemberData = useCallback(async () => {

		let tmpArr: noticListType[] = [];

		let formData = new FormData();
		formData.append('currentPage', '1');
		try {
			await formSubmit(postMemberListUrl, formData, 'GENERAL')
				.then((res) => {
					let data = res.data;
					if(data.success) {
						// console.log('res data', data.response.members);
						data.response.members.map((data: any) => {
							return tmpArr = [...tmpArr, {id: data.lgSeq, lgId: data.lgId, lgName: data.lgName, lgProjectCount: data.lgProjectCount, lgDirSizeGb:data.lgDirSizeGb, lgJoinDate:data.lgJoinDate}];
						})
						setDatas(tmpArr);
					} else {
						return Promise.reject({success: false, code: data.errorCode})
					}
				})
		} catch(e: any) {
			console.log('e', e);
			setPopCode(e.code);
			setPopDp(true);
		}
	},[]);


	/****************************************************** 페이지 초기 세팅 (useEffect) ***************************************************/
	useEffect(() => {
		getMemberData();
	},[getMemberData])

	return (
		<>
			<PageTitle title='회원관리' buttonData='' />
			<div className='tableGrid'>
				<DataGrid
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[ 20, 40, 50 ]}
					rows={datas}
					hideFooterSelectedRowCount
					// checkboxSelection={true}
					columns={memberColumn}
					autoHeight
					onRowClick={viewContent}
					sx={{ 
						fontSize: '1.2rem',
						color: '#333',
						fontFamily: 'Noto Sans KR',
						backgroundColor: '#fff',
						cursor: 'pointer'
					}}
				/>
			</div>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

export default React.memo(MemberList)