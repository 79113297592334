import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import { GeneralButton, Input, InputAlert, PageTitle, Popup, QuillEditorAdmin } from '@components';
import { useNavigate, useParams } from 'react-router-dom';
import { inputCheck } from '@handler';
import { alertControlType, getNoticeDetailUrl, postNoticeProcUrl } from '@env';
import { formSubmit, getData } from '@service';
// import Dompurify from "dompurify"


function NoticeWrite() {
	
	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();
	// 넘어온 값
	const { id } = useParams();
	// 버튼 활성화 여부
	const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
	// 삭제, 수정 구분 팝업창에서 삭제/수정 각각 액션을 달리해주기 위함.
	const [type, setType] = useState<string>('');
	// 처리가 정상적으로 됐는지 여부, 현재 페이지에 머물 것인지, 나갈것인지 확인
	const [result, setResult] = useState<boolean>(false);

	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
		(type === 'D' && result===true) && navigate('/notice', {replace: true});
	},[type, result, navigate]);


	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	const [subject, setSubject] = useState<string>('');
	const [sbjAlertCtl, setSbjAlertCtl] = useState<alertControlType>({dp: false, msg: ''})
	const [desc, setDesc] = useState<string>('');
	const [descAlertCtl, setDescAlertCtl] = useState<alertControlType>({dp: false, msg: ''	})
	
	const subjectHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		// e.stopPropagation();

		let result = inputCheck(e.target.value, 5, '제목');
		if(result.isChecked) {
			setSubject(result.value)
			setSbjAlertCtl({dp: false, msg: ''})
		} else {
			setSbjAlertCtl({dp: true, msg: result.alertMessage})
		}
		
	},[])

	const contentHandler = useCallback((html: string): void => {
		// e.preventDefault();
		let result = inputCheck(html, 10, '내용')
		setDesc(html);	// Editor에서 입력값이 사라지는 현상 대비

		if(result.isChecked) {
			setDesc(result.value)
			setDescAlertCtl({dp: false, msg: ''})
		} else {
			setDescAlertCtl({dp: true, msg: result.alertMessage})
		}
	},[])


	const goList = useCallback((e:React.ChangeEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate('/notice', {replace: true});
	},[navigate])


	/****************************************************** 데이터 가져오기/수정/삭제 ***************************************************/
	const getNotice = useCallback(async () => {

		try {
			await getData(getNoticeDetailUrl + id)
				.then((res) => {
					let data = res.data;
					if(data.success) {
						setSubject(data.response.ntTitle);
						setDesc(data.response.ntContents);
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode);
			setPopDp(true);
		}
	},[id]);

	const noticeSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>, type: string) => {
		e.preventDefault();
		// e.stopPropagation();

		let form = document.getElementsByName('noticeForm')[0];
		setType(type);

		try {
			await formSubmit(postNoticeProcUrl, form, 'GENERAL')
				.then((res) => {
					let data = res.data;
					if(data.success && data.response.resultCode === 0) {
						setResult(true);
						let msgCode = (type==='D') ? 'DELETE_COMPLETE' : 'MODIFY_COMPLETE';
						setPopCode(msgCode)
						setPopDp(true);
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode)
			setPopDp(true);
		}
	},[])

	useEffect(() => {
		(sbjAlertCtl.dp===false && descAlertCtl.dp===false) ? setBtnDisabled(false) : setBtnDisabled(true);
		getNotice();
	},[sbjAlertCtl.dp, descAlertCtl.dp, getNotice])

	// 엔터키 방지
	document.addEventListener('keydown', function(event) {
		if(event.keyCode === 13) {
			event.preventDefault();
		}
	})

	return (
		<>
			<PageTitle title='공지사항' />
			<TableWrap>
				<form name='noticeForm'>
					<fieldset>
						<input type='hidden' name='procMode' value={type} />
						<input type='hidden' name='ntSeq' value={id} />
						<Input
							type='text' 
							name='ntTitle' 
							title='제목' 
							placeholder='제목을 입력해주세요.'
							onchange={subjectHandler}
							alertMessage=''
							maxLength={30}
							readonly={false}
							value={subject}
						/>
						{sbjAlertCtl.dp && <InputAlert alertMsg={sbjAlertCtl.msg} width='100%' />}
						<div style={{height: '50px'}}></div>
						<QuillEditorAdmin value={desc} onChange={contentHandler} style={{height: '500px'}} />
						{/* <div dangerouslySetInnerHTML={{__html: Dompurify.sanitize(String(desc || "loading")) }}></div> */}
						<textarea name='ntContents' defaultValue={desc} style={{display:'none'}} />
						{/*  편집기의 위 header의 높이로 인해 일정 부분 아래와 겹치는 현상 발생하는 문제로 필요 */}
						<div style={{height: '50px'}}></div>
						{descAlertCtl && <InputAlert alertMsg={descAlertCtl.msg} width='100%' />}
						<div className='ButtonWrap'>
							<GeneralButton title='목록' disabled={false} buttonType='Big_Cancel' buttonLink={goList} />&nbsp;&nbsp;&nbsp;&nbsp;
							<GeneralButton title='삭제' disabled={false} buttonType='Big_Normal' buttonLink={(e: any) => noticeSubmit(e, 'D')} />&nbsp;&nbsp;&nbsp;&nbsp;
							<GeneralButton title='수정' disabled={btnDisabled} buttonType='Big_Normal' buttonLink={(e: any) => noticeSubmit(e, 'U')} />
						</div>
					</fieldset>
				</form>
			</TableWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const TableWrap = styled.div`
	width: 100%;	
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	padding: 3rem 2rem;
`

export default React.memo(NoticeWrite)