import { GeneralButton, Input, PageTitle, Popup, Textarea } from '@components';
import { getRequestDetailUrl, RequestCommentType, RequestFileInfoType } from '@env';
import { getData } from '@service';
import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { FullRoundButton } from '@components';
import styled from 'styled-components';
import { downloadFiles } from '@handler';


function RequestDetail() {

  	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	// 넘어온 값
	const { id } = useParams();
	
	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);
	

	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	const [pjName, setPjName] = useState<string>('');
	const [rqTitle, setRqTitle] = useState<string>('');
	const [rqType, setRqType] = useState<string>('');
	const [rqDeadline, setRqDeadline] = useState<string>('');
	const [rqStatus, setRqStatus] = useState<string>('');
	const [rqRanking, setRqRanking] = useState<string>('');
	const [rqOpenYn, setRqOpenYn] = useState<string>('');
	const [rqPersonChargeName, setRqPersonChargeName] = useState<string>('');
	const [rqReporterName, setRqReporterName] = useState<string>('');
	const [rqContents, setRqContents] = useState<string>('');
	const [rqRegDate, setRqRegDate] = useState<string>('');
	const [raTags, setRqTags] = useState<string>('');
	const [comments, setComments] = useState<[]>([]);
	const [attachedFile, setAttachedFile] = useState<[]>([]);

	// text area style
	const textAreaHeight = { height: '30rem' }

	const goList = useCallback((e:React.ChangeEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate('/request', {replace: true});
	},[navigate])


	const pageDataHandling = () => {
	}

	const fileDownload = (e: React.ChangeEvent<HTMLButtonElement>, seq: number, fileName: string) => {
		e.preventDefault();
		downloadFiles(seq, fileName, 'D')
	}


	/****************************************************** 데이터 가져오기/수정/삭제 ***************************************************/
	const getRequestData = useCallback(async () => {

		try {
			await getData(getRequestDetailUrl + id)
				.then((res) => {
					// console.log(res);
					let data = res.data;
					if(data.success) {
						setPjName(data.response.pjName)
						setRqTitle(data.response.rqTitle)
						setRqType(data.response.rqType)
						setRqDeadline(data.response.rqDeadline)
						setRqStatus(data.response.rqStatus)
						setRqRanking(data.response.rqRanking)
						setRqOpenYn(data.response.rqOpenYn)
						setRqPersonChargeName(data.response.rqPersonChargeName)
						setRqReporterName(data.response.rqReporterName)
						setRqContents(data.response.rqContents)
						setRqRegDate(data.response.rqRegDate)
						let tmpTags: string ='';
						type tagType = {rqTag: string}
						data.response.requestTags.map((data: tagType) => {
							return tmpTags = tmpTags + data.rqTag + ', '
						})
						setRqTags(tmpTags)
						setComments(data.response.requestComments)
						setAttachedFile(data.response.requestFiles)
					} else {
						return Promise.reject(data)
					}
				})
		} catch(e: any) {
			setPopCode(e.errorCode);
			setPopDp(true);
		}
	},[id]);

	useEffect(() => {
		getRequestData();
	},[getRequestData])

	
	return (
		<>
			<PageTitle title='문서관리' />
				<ContentWrap>
					<TableWrap width='68%'>
						<FlexDivBox width='100%' padding='0 0 3rem 0'>
							<Input
								type='text' 
								name='rqTitle' 
								title='요청사항 제목' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqTitle}
							/>
						</FlexDivBox>
						<FlexDivBox width='50%' padding='0 1rem 3rem 0'>
							<Input
								type='text' 
								name='pjName' 
								title='프로젝트 이름' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={pjName}
							/>
						</FlexDivBox>
						<FlexDivBox width='30%' padding='0 1rem 3rem 1rem'>
							<Input
								type='text' 
								name='rqReporterName' 
								title='보고자' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={100}
								readonly={true}
								value={rqReporterName}
							/>
						</FlexDivBox>
						<FlexDivBox width='20%' padding='0 0 3rem 1rem'>
							<Input
								type='text' 
								name='rqType' 
								title='요청 유형' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqType}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 1rem 3rem 0'>
							<Input
								type='text' 
								name='rqDeadline' 
								title='기한' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqDeadline}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 1rem 3rem 1rem'>
							<Input
								type='text' 
								name='rqStatus' 
								title='상태' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqStatus}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 0 3rem 1rem'>
							<Input
								type='text' 
								name='rqRanking' 
								title='우선순위' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqRanking}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 1rem 3rem 0'>
							<Input
								type='text' 
								name='rqOpenYn' 
								title='공개여부' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={30}
								readonly={true}
								value={rqOpenYn}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 1rem 3rem 1rem'>
							<Input
								type='text' 
								name='rqPersonChargeName' 
								title='담당자' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={100}
								readonly={true}
								value={rqPersonChargeName}
							/>
						</FlexDivBox>
						<FlexDivBox width='33%' padding='0 0 3rem 1rem'>
							<Input
								type='text' 
								name='rqRegDate' 
								title='등록일시' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={100}
								readonly={true}
								value={rqRegDate}
							/>
						</FlexDivBox>
						<FlexDivBox width='100%' padding='0 0 3rem 0'>
							<Textarea 
								name='rqContents'
								placeholder=''
								maxLength={1000}
								onchange={pageDataHandling}
								title='내용'
								style={textAreaHeight}
								value={rqContents}
							/>
						</FlexDivBox>
						<FlexDivBox width='100%' padding='0 0 3rem 0'>
							<Input
								type='text' 
								name='raTags' 
								title='태그' 
								placeholder=''
								onchange={pageDataHandling}
								alertMessage=''
								maxLength={100}
								readonly={true}
								value={raTags}
							/>
						</FlexDivBox>
						<FlexDivBox width='100%' padding='0 0 3rem 0'>
							{attachedFile.map((data: RequestFileInfoType) => {
								// console.log(data);
								return (
									<FullRoundButton id={data.rfSeq} title={data.rfFilename} onClick={(e: React.ChangeEvent<HTMLButtonElement>) => fileDownload(e, data.rfSeq, data.rfFilename)} buttonType='' />
								)
							})}
						</FlexDivBox>
						<div className='ButtonWrap'>
							<GeneralButton title='목록' disabled={false} buttonType='Big_Cancel' buttonLink={goList} />
						</div>
					</TableWrap>
					<TableWrap width='30%'>
						댓글
						{
							comments.map((data: RequestCommentType) => {
								return (
								<FlexDivBox width='100%' padding='0 0 3rem 0'>
									{data.rcRegisterName} | {(data.rcType === 0) ? '팀원에게만 공개' : '전체공개'} | {data.rcRegDateYmd}
									<Textarea name='comment' placeholder='' maxLength={1000} onchange={pageDataHandling} title='' style={{height: '10rem'}} value={data.rcContents}/>
								</FlexDivBox>
								)
							})
						}
					</TableWrap>
				</ContentWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

type tableWrapType = { width: string }
const TableWrap = styled.div<tableWrapType>`
	width: ${(props) => props.width};
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	padding: 3rem 2rem;
	margin-right: 1rem;
	display: flex;
	flex-wrap: wrap;
`
interface FlexDivType {
	width: string,
	padding?: string|null
}
const FlexDivBox = styled.div<FlexDivType>`
	// border: 1px solid red;
	width: ${(props) => props.width};
	padding: ${(props) => props.padding};
`;

const ContentWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export default RequestDetail