import React, { useCallback, useEffect, useState } from 'react'
import { PageTitle, Popup } from '@components'
import { DataGrid } from '@mui/x-data-grid'
import { formSubmit } from '@service'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { postProjectListUrl } from '@env'

function ProjectList() {
	/****************************************************** 공통 기본 정의 ***************************************************/
	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	const navigate = useNavigate();

	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);
	

	/****************************************************** Data Grid 관련 정의 ***************************************************/
	const [pageSize, setPageSize] = useState<number>(20);		// 한 페이지 사이트
	const noticeColumn: any = [
		{ 
			"field": "id", 
			"headerName": "번호", 
			"width": 80,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "pjName", 
			"headerName": "프로젝트명", 
			"flex": 1,
			"headerAlign": "center",
			"align": "left"
		},
		{ 
			"field": "pjMemberCount", 
			"headerName": "멤버수", 
			"width": 100,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "pjCustomerCount", 
			"headerName": "고객수", 
			"width": 100,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "pjRegId", 
			"headerName": "오너아이디", 
			"width": 300,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "pjRegName", 
			"headerName": "오너이름", 
			"width": 150,
			"headerAlign": "center",
			"align": "center"
		},
		{ 
			"field": "pjRegDate", 
			"headerName": "등록일시", 
			"width": 250,
			"headerAlign": "center",
			"align": "center"
		}
	];
	
	/****************************************************** Data 가져오기 ***************************************************/
	const [datas, setDatas] = useState<noticListType[]>([]);
	interface noticListType {
		id: string,
		pjName: string,
		pjMemberCount: number,
		pjCustomerCount: number,
		pjRegId: string,
		pjRegName: string,
		pjRegDate: string
	}

	const getProjectList = useCallback(async () => {

		let tmpArr: noticListType[] = [];

		let formData = new FormData();
		formData.append('currentPage', '1');

		try {
			await formSubmit(postProjectListUrl, formData, 'GENERAL')
				.then((res) => {
					let data = res.data;
					if(data.success) {
						// console.log('res data', data.response.notices);
						data.response.projects.map((data: any) => {
							return tmpArr = [...tmpArr, {id: data.pjSeq, pjName: data.pjName, pjMemberCount: data.pjMemberCount, pjCustomerCount:data.pjCustomerCount, pjRegId:data.pjRegId, pjRegName:data.pjRegName, pjRegDate:data.pjRegDate}];
						})
						setDatas(tmpArr);
					} else {
						return Promise.reject({success: false, code: data.errorCode})
					}
				})
		} catch(e: any) {
			// console.log('e', e);
			setPopCode(e.code);
			setPopDp(true);
		}
	},[]);


	/****************************************************** 페이지 초기 세팅 (useEffect) ***************************************************/
	useEffect(() => {
		getProjectList();
	},[getProjectList])
	
	return (
		<>
			<PageTitle title='프로젝트 관리' />
			<TableGrid>
				<DataGrid
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[ 20, 40, 50 ]}
					rows={datas}
					hideFooterSelectedRowCount
					// checkboxSelection={true}
					columns={noticeColumn}
					autoHeight
					sx={{ 
						fontSize: '1.2rem',
						color: '#333',
						fontFamily: 'Noto Sans KR',
						backgroundColor: '#fff',
						cursor: 'pointer'
					}}
				/>
			</TableGrid>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const TableGrid = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
`

export default React.memo(ProjectList)