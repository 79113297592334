import React from 'react'
import styled from 'styled-components'


interface AlertProps {
	alertMsg: string,
	width: string
}

export default function InputAlert({
	alertMsg,
	width
}: AlertProps) {
	return (
		<>
			<Alert width={width}>{alertMsg}</Alert>
		</>
	)
}

interface styledProps {
	width: string
}

const Alert = styled.p<styledProps>`
	color: var(--alertFontColor);
	font-size: var(--alertFontSize);
	font-weight: var(--alertFontWeight);
	margin-top: var(--alertMarginTop);
	// position: absolute;
	width: ${(props) => props.width};
	word-break: normal;
	// border: 1px solid red;
`;