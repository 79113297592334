import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Input, GeneralButton, InputAlert, Popup  } from '@components';
import { inputCheck, setSessionItem } from '@handler';
import { useNavigate } from 'react-router-dom';
import { checkSignIn, formSubmit } from '@service';
import { acTokenName, adminUserID, adminUserName, alertControlType, authName, postManageLoginUrl } from '@env';

function Login() {

	/****************************************************** 공통 기본 정의 ***************************************************/
	// 페이지 이동
	const navigate = useNavigate();

	// 팝업 처리
	const [popCode, setPopCode] = useState<string>('');
	const [popDp, setPopDp] = useState<boolean>(false);

	// background, cancel button, X button handler
	const bgHandler = useCallback((e: any) => {
		e.preventDefault();
		setPopDp(false);
	},[]);

	// 등록, 저장 버튼의 활성화
	// const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

	/****************************************************** 페이지 내 데이터 정의 ***************************************************/
	// id
	const [userId, setUserId] = useState<string>('');
	const [idMsgCtl, setIdMsgCtl] = useState<alertControlType>({dp: true, msg:''});

	// password
	const [userPwd, setUserPwd] = useState<string>('');
	const [pwdMsgCtl, setPwdMsgCtl] = useState<alertControlType>({dp: true, msg:''});


	/****************************************************** 페이지 데이터 핸들링 ***************************************************/
	// id handler
	const userIdHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUserId(e.target.value)
	},[])

	const userPwdHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUserPwd(e.target.value);
	},[]);


	/****************************************************** 데이터 등록/수정/삭제 ***************************************************/
	const loginSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// 아이디 체크
		let result = inputCheck(userId, 5, '아이디');
		if(result.isChecked) {
			setUserId(result.value);
			setIdMsgCtl({dp: false, msg: ''})
		} else {
			setIdMsgCtl({dp: true, msg: result.alertMessage})
			return false;
		}

		result = inputCheck(userPwd, 5, '비밀번호');
		if(result.isChecked) {
			setUserPwd(result.value);
			setPwdMsgCtl({dp: false, msg: ''})
		} else {
			setPwdMsgCtl({dp: true, msg: result.alertMessage})
			return false;
		}

		try {
			await formSubmit(postManageLoginUrl, document.getElementsByName('loginForm')[0], 'GENERAL')
				.then((res) => {
					let data = res.data;
					// console.log('login', data);
					if(data.success) {
						setSessionItem(adminUserID, data.response.mgId);
						setSessionItem(adminUserName, data.response.mgName);
						setSessionItem(acTokenName, data.response.mgAccessToken);
						setSessionItem(authName, true);
						// 로그인하면 회원관리로 먼저 보낸다.
						navigate('/member', {replace: true});
					} else {
						return Promise.reject({success: false, code: data.errorCode})
					}
				})
		} catch(e: any) {
			setPopCode(e.code);
			setPopDp(true);
		}
	},[userId, userPwd, navigate]);

	
	/****************************************************** 페이지 초기 세팅 (useEffect) ***************************************************/
	useEffect(() => {
		//로그인 한 사용자라면 메인 페이지로 보낸다.
		(checkSignIn()) && navigate('/member', {replace: true});
		// 버튼을 활성화 한다.
		// (idMsgCtl.dp===false && pwdMsgCtl.dp===false) ? setBtnDisabled(false) : setBtnDisabled(true);
	},[navigate])

	return (
		<>
			<BasicWrap>
				<LoginWrap>
					<PageTitle>Sign In</PageTitle>
					<FormWrap>
						<form onSubmit={loginSubmit} name='loginForm'>
							<fieldset>
								<div className='FieldDiv'>
									<Input
										type='text' 
										name='mgId' 
										title='아이디' 
										placeholder='아이디를 입력해주세요.' 
										onchange={userIdHandler}
										alertMessage=''
										maxLength={15}
										readonly={false}
										// value={userId}
									/>
									{idMsgCtl.dp && <InputAlert alertMsg={idMsgCtl.msg} width='100%' />}
								</div>
								<div className='FieldDiv'>
									<Input
										type='password' 
										name='mgPassword' 
										title='비밀번호' 
										placeholder='비밀번호를 입력해주세요.' 
										onchange={userPwdHandler}
										alertMessage=''
										maxLength={20}
										readonly={false}
										value={userPwd}
									/>
									{pwdMsgCtl.dp && <InputAlert alertMsg={pwdMsgCtl.msg} width='100%' />}
								</div>
								<GeneralButton title='로그인' disabled={false} buttonType='Big_Normal' buttonLink={loginSubmit} />
							</fieldset>
						</form>
					</FormWrap>
				</LoginWrap>
			</BasicWrap>
			{popDp && <Popup popcode={popCode} okFunc={bgHandler} bgFunc={bgHandler} />}
		</>
	)
}

const BasicWrap= styled.div`
	background-color: var(--bgColorPageBasic);
	position: relative;
	width: 100vw;
	height: 100vh;
	// border: 1px solid red;
`

const LoginWrap = styled.div`
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const FormWrap = styled.div`
	background-color: var(--bgColorContentBasic);
	border-radius: var(--radiusBasic);
	width: 30rem;
	height: 25rem;
	padding: 3rem 2rem;
`

const PageTitle = styled.div`
	width: 100%;
	height: 90px;
	color: #333;
	background-color: var(--bgColorPageBasic);
	text-align: center;
	font-weight: 600;
	font-size: 60px;
	// border: 1px solid red;
`

export default Login