/********************************************************************************************************

	Login Service Initialization

*********************************************************************************************************/
export const tokenExpireDay = 7;


/********************************************************************************************************

	File Service Initialization

*********************************************************************************************************/
export const maxFileSize = 10;	// each file
export const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'XLS', 'XLSX', 'DOC', 'DOCX', 'PPT', 'PPTX', 'ZIP', 'PDF','TXT'];


/********************************************************************************************************

	Gookie, Token Naming for localStorage

*********************************************************************************************************/
export const cookieExpireDay = 7;
export const acTokenName = 'GIROCADMIN_ACTOKEN';
export const authName = "GIROC_ISAUTHENTICATED";
export const adminUserName = 'GIROCADMIN_USERNAME';
export const adminUserID = 'GIROCADMIN_USERID';

export const rfTokenName = 'GIROCADMIN_RFTOKEN';