import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

interface InputProps {
	title: string,
	name: string,
	type: string,
	placeholder: string,
	onchange: (e: React.ChangeEvent<HTMLInputElement>) => void,
	alertMessage: string,
	maxLength: number,
	value?: any,
	readonly: boolean,
	// alertWidth: string
}

interface refProps {
	ref: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> | null
}

function Input({
	title,
	name,
	type,
	placeholder,
	onchange,
	alertMessage,
	maxLength,
	value = null,
	readonly,
	// alertWidth
}: InputProps, ref: refProps): React.ReactElement {

	return (
		<>
			<legend>{title}</legend>
			<input 
				name={name} 
				type={type} 
				placeholder={placeholder} 
				onChange={onchange} 
				maxLength={maxLength} 
				defaultValue={value}
				readOnly={readonly} 
				// ref={ref}
			/>
		</>
	)
}

// export default forwardRef(Input);
export default Input;